import * as React from 'react';
import Seo from '../components/seo';
import Nav from '../components/nav2/nav';
import HeroSection from '../components/HeroSection/HeroSection';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';
import GallerySection from '../components/GallerySection/GallerySection';
import Footer from '../components/Footer8/Footer';

const ClinicIndex = ({ data, location }) => {
  return (
    <div>
      <Nav
        title="古材活用ドットコム"
        navItems={[
          {
            id: 1,
            name: '古材とは？',
            link: '#section1',
            dropdown: false,
          },
          {
            id: 2,
            name: '古材の魅力',
            link: '#section2',
            dropdown: false,
          },
          {
            id: 3,
            name: '取り扱い古材',
            link: '#section4',
            dropdown: false,
          },
          {
            id: 4,
            name: '大工の緒方について',
            link: '#section5',
            dropdown: false,
          },
        ]}
      />
      <HeroSection
        heading="古材活用ドットコム"
        description="古民家の解体やリノベーションによって出た古材を再利用し、新しい家づくりに活用します。"
        imagePath="/minka.jpg"
      />

      <FeatureSectionLeft
        imageSrc="/ogata101.jpg"
        imageAlt="古い家に使われていた木材"
        title="古材とは？？"
        description={`古材とは、その名の通り
        「古い家に使われていた木材」を指します。
        
        今までは、多くの古材が家の解体やリノベーションによって
        再利用可能な資源は廃棄されてしまうといった現状でした。
        そんな中、私たち家を扱う仕事をしている大工として
        「資源の再利用はできないか」と考え、そして
        国を挙げた動きがついに始まったのです。
        
        脱炭素社会の実現に資する等のための建築物等における
        木材の利用の促進に関する法律（まちの木造化推進法）
        の実現に向けて、総務大臣、文部科学大臣、経済産業大臣、
        国土交通大臣、環境大臣を本部員とした
        木材利用促進本部により、取り組みが進んでいます。`}
        sectionId="section1"
      />
      <FeatureSectionRight
        imageSrc="/ogata102.jpg"
        imageAlt="Example image"
        title="古材の魅力"
        description={`古材の質は、その当時使用されていた木材の質が関係しています。

        戦前の日本では、木材が豊富だったため、        
        一般の民家や家屋の建築資材として、質の高い木材が使用されていました。
        
        質の高い木材は、数十年の時が流れても、いつまでもそのクオリティを保ち続けます。`}
        sectionId="section2"
      />
      <FeatureSectionCenterNoimage
        description={`古材再利用・古民家再生の相談は大工の緒方へ！`}
        ctrLink="https://www.daikuno-ogata.com/contact"
        ctrText="ご相談はこちら"
        sectionId="section3"
      />
      <GallerySection
        title="取り扱い古材"
        description={`こんな古材を取り扱っています！`}
        imgs={[
          { id: 1, src: '/ogata/ogata-l-1.jpg', alt: 'Image 1' },
          { id: 2, src: '/ogata/ogata-l-2.jpg', alt: 'Image 2' },
          { id: 3, src: '/ogata/ogata-l-3.jpg', alt: 'Image 3' },
          { id: 4, src: '/ogata/ogata-l-4.jpg', alt: 'Image 4' },
          { id: 5, src: '/ogata/ogata-l-5.jpg', alt: 'Image 5' },
          { id: 6, src: '/ogata/ogata-l-6.jpg', alt: 'Image 6' },
          { id: 7, src: '/ogata/ogata-l-7.jpg', alt: 'Image 7' },
          { id: 8, src: '/ogata/ogata-l-8.jpg', alt: 'Image 8' },
          { id: 9, src: '/ogata/ogata-l-9.jpg', alt: 'Image 9' },
          { id: 10, src: '/ogata/ogata-l-10.jpg', alt: 'Image 10' },
          { id: 11, src: '/ogata/ogata-l-11.jpg', alt: 'Image 11' },
          { id: 14, src: '/ogata/ogata-l-14.jpg', alt: 'Image 14' },
          { id: 16, src: '/ogata/ogata-l-16.jpg', alt: 'Image 16' },
          { id: 17, src: '/ogata/ogata-l-17.jpg', alt: 'Image 17' },
          { id: 18, src: '/ogata/ogata-l-18.jpg', alt: 'Image 18' },
        ]}
        sectionId="section4"
      />
      <FeatureSectionLeft
        imageSrc="/ogata103.jpeg"
        imageAlt="Example image"
        title="大工の緒方について"
        description={`株式会社大工の緒方は、職人の町北九州発祥の
        古民家再生に精通したプロの大工集団です。
        
        国土交通大臣登録「住宅リフォーム事業者団体」に登録しており、
        和風建築のリフォームを得意としています。
        
        建造から時が経った民家を
        あなたの理想に沿ったお家へとリノベーションいたします。
        「古民家再生」は、安心の（株）大工の緒方へご依頼下さい。`}
        ctrLink="https://www.daikuno-ogata.com/"
        ctrText="公式サイトはこちら"
        sectionId="section5"
      />
      <FeatureSectionCenterNoimage
        description={`古材再利用・古民家再生の相談は大工の緒方へ！`}
        ctrLink="https://www.daikuno-ogata.com/contact"
        ctrText="ご相談はこちら"
        sectionId="section6"
      />
      <Footer copyrightText="株式会社 大工の緒方" />
    </div>
  );
};

export default ClinicIndex;

export const Head = () => (
  <Seo
    cover={'/ogata101.jpg'}
    title="古材活用ドットコム"
    description="古民家の解体やリノベーションによって出た古材を再利用し、新しい家づくりに活用します。"
  />
);
