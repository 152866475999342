// extracted by mini-css-extract-plugin
export var content = "FeatureSection-module--content--e34de";
export var description = "FeatureSection-module--description--14bde";
export var featureSection = "FeatureSection-module--featureSection--ad252";
export var featureSectionInner = "FeatureSection-module--featureSectionInner--418e0";
export var featureSectionPrimary = "FeatureSection-module--featureSectionPrimary--3d7f7";
export var featureSectionSecondary = "FeatureSection-module--featureSectionSecondary--09a99";
export var head = "FeatureSection-module--head--ad2e5";
export var headSubtext = "FeatureSection-module--headSubtext--a63fc";
export var headText = "FeatureSection-module--headText--52e98";
export var image = "FeatureSection-module--image--af81d";
export var title = "FeatureSection-module--title--75c27";