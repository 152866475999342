import React, { useState, useEffect } from 'react';
import * as styles from './GallerySection.module.css';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import FormattedText from '../commons/FormattedText/FormattedText';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // この行はLightboxのスタイルを読み込むためのものです。

type ColorType = 'primary' | 'secondary';

type Image = {
  id: number;
  src: string;
  alt: string;
};

type GallerySectionProps = {
  imgs: Image[];
  colorType: ColorType;
  title: string;
  sectionId: string;
  subtitle: string;
  description: string;
};

const GallerySection: React.FC<GallerySectionProps> = ({
  imgs,
  title,
  subtitle,
  colorType,
  sectionId,
  description,
}) => {
  const [images, setImages] = useState(imgs);
  const [visibleImages, setVisibleImages] = useState(new Set());
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  let gallerySectionClass = styles.gallerySectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    gallerySectionClass = styles.gallerySectionPrimary;
  } else if (colorType === 'secondary') {
    gallerySectionClass = styles.gallerySectionSecondary;
  }

  // スクロールイベントハンドラ
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    images.forEach((img, index) => {
      const imgElement = document.getElementById(`gallery-img-${img.id}`);
      if (imgElement) {
        const rect = imgElement.getBoundingClientRect();
        if (rect.top <= windowHeight) {
          setVisibleImages(
            (prevVisibleImages) => new Set(prevVisibleImages.add(img.id)),
          );
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [images]);
  const openLightbox = (index: React.SetStateAction<number>) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  return (
    <ScrollAnimation>
      <section className={gallerySectionClass} id={sectionId}>
        <div className={styles.gallerySectionInner}>
          <div className={styles.content}>
            {title || subtitle ? (
              <h2 className={styles.head}>
                {title && <span className={styles.headText}>{title}</span>}
                {subtitle && (
                  <span className={styles.headSubtext}>{subtitle}</span>
                )}
              </h2>
            ) : null}
            {description && (
              <p className={styles.description}>
                <FormattedText text={description} />
              </p>
            )}
            <ul className={styles.gallery}>
              {images.map((image, index) => (
                <li
                  key={image.id}
                  id={`gallery-img-${image.id}`}
                  className={`${styles.galleryItem} ${
                    visibleImages.has(image.id)
                      ? styles.visible
                      : styles.flipLeft
                  }`}
                  onClick={() => openLightbox(index)}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className={styles.galleryImage}
                  />
                </li>
              ))}
            </ul>
            {lightboxOpen && (
              <Lightbox
                mainSrc={images[currentImage].src}
                nextSrc={images[(currentImage + 1) % images.length].src}
                prevSrc={
                  images[(currentImage + images.length - 1) % images.length].src
                }
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() =>
                  setCurrentImage(
                    (currentImage + images.length - 1) % images.length,
                  )
                }
                onMoveNextRequest={() =>
                  setCurrentImage((currentImage + 1) % images.length)
                }
              />
            )}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default GallerySection;
