import React from 'react';
import * as styles from './HeroSection.module.css';

interface HeroSectionProps {
  heading: string;
  subHeading?: string;
  description: string;
  imagePath: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  subHeading,
  description,
  imagePath,
}) => {
  return (
    <div
      className={styles.heroSection}
      style={{ backgroundImage: `url(${imagePath})` }}
    >
      <div className={styles.textSection}>
        <h1 className={styles.heading}>{heading}</h1>
        {subHeading ? <h2 className={styles.subHeading}>{subHeading}</h2> : ''}
        <h2 className={styles.subHeading}>{subHeading}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default HeroSection;
