import React, { useState, useEffect } from 'react';
import * as styles from './nav.module.css';
import { Link } from 'gatsby';

/**
 * ナビゲーションコンポーネント
 *
 * このコンポーネントはナビゲーションバーを描画します。ナビゲーションバーはビューポートのサイズに基づいて
 * レスポンシブになります。小さいビューポートの場合、ハンバーガーボタンを描画します。
 * ビューポートが大きい場合、すべてのナビゲーションリンクを表示します。
 *
 * @param {Object[]} navItems - 表示するナビゲーションアイテムの配列。各アイテムは 'id', 'name', 'link' プロパティと
 * オプションで 'dropdown' および 'subItems' を含むオブジェクトです。 'dropdown' が true の場合、
 * 'subItems' は 'name' および 'link' プロパティを持つオブジェクトの配列であることが期待されます。
 * @returns {ReactElement} 描画されたナビゲーションバー
 */

const Nav = ({ title, navItems }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth <= 600;
    }
    return false;
  });

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setIsMobile(window.innerWidth <= 600);
      });
    }
  }, []);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      setIsActive(!isActive);
    }
  };

  return (
    <header className={styles.header} id="g_navi">
      <div className={styles.header__wrapper}>
        <Link to="/" className={styles.header__brand}>
          {title}
        </Link>
        <button
          onClick={handleClick}
          onKeyUp={handleKeyUp}
          className={
            isActive
              ? `${styles.header__hamburger} ${styles.active}`
              : `${styles.header__hamburger}`
          }
          aria-expanded={isActive}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul
          className={
            isActive
              ? `${styles.header__navList} ${styles.active}`
              : styles.header__navList
          }
        >
          {navItems.map((item) => (
            <li key={item.id} className={styles.header__navItem}>
              <a
                href={item.link}
                target={item.dropdown ? '' : ''}
                onMouseEnter={() => (isMobile ? null : setActiveMenu(item.id))}
                onClick={handleClick}
              >
                {item.name}
              </a>
              {item.dropdown && (isMobile || activeMenu === item.id) && (
                <button
                  className={styles.header__subList}
                  onMouseLeave={() => (isMobile ? null : setActiveMenu(null))}
                  aria-haspopup="true"
                  aria-expanded={isMobile || activeMenu === item.id}
                  aria-controls="submenu-id"
                >
                  <ul id="submenu-id">
                    {item.subItems.map((subItem, index) => (
                      <li key={index}>
                        <a href={subItem.link}>{subItem.name}</a>
                      </li>
                    ))}
                  </ul>
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Nav;
