// extracted by mini-css-extract-plugin
export var content = "GallerySection-module--content--3223e";
export var description = "GallerySection-module--description--fbc4c";
export var flipLeft = "GallerySection-module--flipLeft--48f88";
export var gallery = "GallerySection-module--gallery--d9b7b";
export var galleryImage = "GallerySection-module--galleryImage--928fc";
export var galleryItem = "GallerySection-module--galleryItem--32893";
export var gallerySectionInner = "GallerySection-module--gallerySectionInner--d55b3";
export var gallerySectionPrimary = "GallerySection-module--gallerySectionPrimary--6154f";
export var gallerySectionSecondary = "GallerySection-module--gallerySectionSecondary--1dc4c";
export var head = "GallerySection-module--head--5aafe";
export var headSubtext = "GallerySection-module--headSubtext--95121";
export var headText = "GallerySection-module--headText--e4125";
export var visible = "GallerySection-module--visible--bde7b";